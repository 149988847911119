import React from "react";

import {Row, Col, Statistic, Icon, Progress, Tooltip, Spin, Transfer, Table, message, Popconfirm} from 'antd';

import './style/Dashboard.page.scss';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countPictures: 0,
            pictureSize: 0,
            countUsers: 0,
            countPublishPictures: 0,
            loading: true,
            tablePictures: [],
            tablePublishPictures: [],
            Users : [],
        };

        this.App = window.App;
        this.API = window.App.API;

        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {

        var pictureSize = 0;
        var countPictures = 0;
        var countPublishPictures = 0;
        var tablePictures = [];

        var APICountPictures = await this.API.getEntries("photos?_limit=-1");
        var APICountUsers = await this.API.getEntries("users?_limit=-1");

        APICountPictures.map((picture, index) => {
            tablePictures.push(
                {
                    key: picture.id,
                    title: picture.titre,
                    description: picture.description,
                    publish: picture.publish,
                    url: this.App.APIUrl + picture.file.url
                }
            );

            if (picture.publish) {
                countPublishPictures += 1
            }
            countPictures += 1;
            pictureSize += parseInt(picture.file.size);
        });

        var tablePublishPictures = tablePictures.filter(item => item.publish).map(item => item.key);

        this.setState({
            countPictures: countPictures,
            countPublishPictures: countPublishPictures,
            pictureSize: pictureSize * 1024,
            countUsers: APICountUsers.length,
            Users: APICountUsers,
            loading: false,
            tablePictures: tablePictures,
            tablePublishPictures: tablePublishPictures
        });
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    handleChange(nextTargetKeys, direction, moveKeys) {

        this.setState({loading: true});

        //Mise en ligne
        if (direction === "right") {
            moveKeys.map((id, index) => {
                message.info('Traitement en cours : '+ index +'/'+moveKeys.length);
                try {
                    this.API.updateEntry("photos", id, {publish: true, updated_at: new Date().getTime()})
                } catch (e) {
                    message.error('API Indisponible');
                }

            });
        }
        //Mise à l'ombre
        if (direction === "left") {
            moveKeys.map((id, index) => {
                message.info('Traitement en cours : '+ index +'/'+moveKeys.length);
                try {
                    this.API.updateEntry("photos", id, {publish: false, updated_at: new Date().getTime()})
                } catch (e) {
                    message.error('API Indisponible');
                }
            });
        }
        setTimeout(()=>{message.destroy(); message.success('Traitement terminé'); this.componentDidMount();}, 500);
    };

    _deleteUser(id, type) {
        try {
            this.API.updateEntry("users", id, {blocked: type})
        } catch (e) {
            message.error('API Indisponible');
        }
        message.success('Utilisateur mis en sommeil');
        setTimeout(this.componentDidMount(), 200);
    }


    render() {

        var {
            pictureSize,
            countPictures,
            countPublishPictures,
            countUsers,
            tablePictures,
            tablePublishPictures,
            loading,
            Users
        } = this.state;

        var percentSize = (pictureSize / (700 * 1024 * 1024 * 1024)) * 100;
        var percentPictures = (countPublishPictures / countPictures) * 100;

        const tableColumns = [
            {
                title: 'Username',
                dataIndex: 'username',
                key: 'username',
                render: text => <p>{text}</p>,
            }, {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                render: text => <p>{text}</p>,
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                render: text => <p>{text.name}</p>,
            },
            {
                title: () => <Icon type={'picture'}/>,
                key: 'nbcomments',
                render: text => <p>{text.photos.length}</p>,
            },
            {
                title: () => <Icon type={'message'}/>,
                key: 'nbphotos',
                render: text => <p>{text.comments.length}</p>,
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => {
                    if (text.blocked) {
                        return (
                            <span>
                                    <Popconfirm onConfirm={() => this._deleteUser(text.id, null)}
                                                title="Etes vous sur de vouloir debloquer cet utilisateur ？"
                                                okText="Oui" cancelText="Non">
                                        <a>Debloquer</a>
                                    </Popconfirm>
                                </span>)
                    } else {
                        return (
                            <span>
                                <Popconfirm onConfirm={() => this._deleteUser(text.id, 1)}
                                            title="Etes vous sur de vouloir bloquer cet utilisateur ？" okText="Oui"
                                            cancelText="Non">
                                    <a>Bloquer</a>
                                </Popconfirm>
                                </span>)
                    }
                },
            }];

        return (
            <div id="Dashboard">
                <Row type={'flex'} justify="center">
                    <Col sm={24} span={24}>
                        <h1>Dashboard de la plateforme</h1>
                    </Col>
                </Row>
                <Row type={'flex'} justify="center">
                        <Col md={6} >
                            <Spin spinning={loading} tip="Chargement...">
                            <Statistic title="Photos Totales" value={countPictures} prefix={<Icon type="picture"/>}/>
                            </Spin>
                        </Col>
                        <Col md={6}>
                            <Spin spinning={loading} tip="Chargement...">
                            <p>Utilisation de la plateforme</p>
                            <Tooltip title={this.formatBytes(pictureSize) + ' utilisés sur les 500GB disponibles'}>
                                <Progress type="dashboard" successPercent={percentSize} percent={100}
                                          format={() => this.formatBytes(pictureSize)}/>
                            </Tooltip>
                            </Spin>
                        </Col>
                        <Col md={6}>
                            <Spin spinning={loading} tip="Chargement...">
                            <p>Etats des photos</p>
                            <Tooltip title={countPublishPictures + ' en ligne sur ' + countPictures}>
                                <Progress type="dashboard" percent={100} successPercent={percentPictures}
                                          format={() => countPublishPictures + '/' + countPictures}/>
                            </Tooltip>
                            </Spin>
                        </Col>
                        <Col md={6}>
                            <Spin spinning={loading} tip="Chargement...">
                            <Statistic title="Nombres utilisateurs" value={countUsers} prefix={<Icon type="user"/>}/>
                            </Spin>
                        </Col>
                </Row>
                <Row type={'flex'} justify="center">
                    <Col sm={24}><h1>Publications</h1></Col>
                    <Col offset={2} sm={20} style={{display: 'flex', justifyContent: 'center'}}>
                        <Spin spinning={loading} tip="Chargement...">
                            <Transfer
                                showSearch
                                filterOption={(inputValue, item) =>
                                    item.title.indexOf(inputValue) !== -1 || item.description.indexOf(inputValue) !== -1}
                                className="tree-transfer"
                                style={{textAlign: "left"}}
                                align={'left'}
                                dataSource={tablePictures}
                                targetKeys={tablePublishPictures}
                                titles={['En attente', 'Publié']}
                                onChange={this.handleChange}
                                lazy={{height: '100%', offset: 32}}
                                render={item => {
                                    const customLabel = (
                                        <div style={{maxHeight: 280, overflow: 'hidden'}}>
                                            <p>{item.title} - { this._subDescription(item.description)
                                            }...</p>
                                            <img style={{width: '100%'}} alt={item.title} src={item.url}/>
                                        </div>

                                    );
                                    return {
                                        label: customLabel,
                                        value: item.titre,
                                    };
                                }}
                            />
                        </Spin>
                    </Col>
                </Row>
                <Row type={'flex'} justify="center" style={{marginBottom : 200}}>
                    <Col sm={24}><h1>Utilisateurs</h1></Col>
                    <Spin spinning={loading} tip="Chargement...">
                        <Col  offset={2} sm={20} style={{display: 'flex', justifyContent: 'center'}}>
                            <Table columns={tableColumns} dataSource={Users}/>
                        </Col>
                    </Spin>
                </Row>
            </div>
        )
    }

    _subDescription(description){
        if (typeof description !== "undefined" && description !== null){
            return (description.substring(0, 20))
        }
        else{
            return "Pas de description"
        }
    }
}

export default Dashboard;
