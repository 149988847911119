import React from 'react';
import './App.scss';
import 'antd/dist/antd.css';

/***Library***/
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Strapi from 'strapi-sdk-javascript';
import {StrAPI} from './settings.api_prod';
import { Button, notification, Layout, BackTop } from 'antd';

/***Pages***/
import Home from './pages/Home.page';
import Dashboard from './pages/Dashboard.page';
import CGU from './pages/CGU.page';
import Albums from "./pages/Albums.page";
import PrivateRoute from './security/PrivateRoute.security';
import NotFound from './security/NotFound.security';

/***Component***/
import Header from './component/Header.component';

const { Footer } = Layout;

class App extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      me : null,
    };

    this.APIUrl = StrAPI;
    this.API = new Strapi(this.APIUrl);

    window.App = this;
  }

  componentDidMount() {
    if(!window.localStorage.getItem('cookiesAccepted')){
      this._openCookies();
    }
    this.setState({me : JSON.parse(sessionStorage.getItem('me'))})
  }

  _openCookies () {
    const key = `open${Date.now()}`;
    const btn = (
        <Button type="primary" size="small" onClick={() => notification.close(key)}>
          J'accepte
        </Button>
    );
    notification.open({
      placement : 'bottomRight',
      message: 'Cookies',
      description:
          '"Nous utilisons les cookies afin de fournir les services et fonctionnalités proposés sur notre site et afin d’améliorer l’expérience de nos utilisateurs. Les cookies sont des données qui sont téléchargés ou stockés sur votre ordinateur ou sur tout autre appareil.\n' +
          'En cliquant sur ”J’accepte”, vous acceptez l’utilisation des cookies. Vous pourrez toujours les désactiver ultérieurement. Si vous supprimez ou désactivez nos cookies, vous pourriez rencontrer des interruptions ou des problèmes d’accès au site."',
      btn,
      key,
      onClick : () => {localStorage.setItem('cookiesAccepted', true);}
    });
  };

  render (){

    var {me, album} = this.state;

    return(
    <Layout className="App">
     <Header me={me}/>
      <Router>
        <Switch>
          <PrivateRoute exact path={"/Dashboard"} component={() => <Dashboard/>}/>
          <Route exact path={"/CGU"} component={() => <CGU/>}/>
          <Route path={"/Albums"} component={() => <Albums/>}/>
          <Route exact path={"/"} component={() => <Home/>}/>
          <Route path={""} component={() => <NotFound/>}/>
        </Switch>
      </Router>
      <BackTop />
      <Footer id={'footer'}>Copyright {new Date().getFullYear()} - <a href={'https://hebi-dev.com'}>Romain P. (P140)</a> - <a href={'https://www.arpete.com'}>AETA</a> - <a href={'/cgu'}>CGU</a> - AETA-Pictures v1.0 </Footer>
    </Layout>
    )
  }

 }

export default App;
