import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './core/App';
import * as serviceWorker from './serviceWorker';


if (window.location.protocol === "http:"){
    window.location.href = "https://igalerie.arpete.com";
}

ReactDOM.render(
    <App />
    , document.getElementById('root'));

serviceWorker.register();
