import React from "react";

import {Row, Col, Skeleton, Card, Tooltip} from 'antd';

import './style/Albums.page.scss';


class Albums extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            albums: null
        };
        this.App = window.App;
        this.API = window.App.API;
    }

    async componentDidMount() {

        var APIPlatform = await this.API.getEntries("promotions?_limit=-1");

        this.setState({
            albums: APIPlatform,
        });
    }

    _onAlbumSelect(album){
       sessionStorage.setItem("album", album.id);
        setTimeout(()=>window.location.pathname = "/", 500);
    }

    render() {
        const {Meta} = Card;
        var {albums} = this.state;

        return (
            <div id="Albums">
                <Row type={"flex"} justify="space-between" gutter={[8, 8]}>
                    {!albums && [...Array(30)].map((e, i) =>
                        <Col key={i} xs={{span : 32}} sm={{span : 24}} md={{span : 16}} lg={{span : 8}}>
                            <Card
                                className={"AlbumCard"}
                                style={{width: 240, boxShadow : '0px 0px 5px 0px '}}
                            >
                                <Skeleton loading={true} active>
                                <Meta  title="loading" description="loading"/>
                                </Skeleton>
                            </Card>
                        </Col>
                    )
                    }
                    {albums && albums.map((album, index) => {
                        var url = "/assets/Insigne-AETA.png";
                        var hoverActive = false;
                        if (album.photos.length !== 0){
                            hoverActive = true;
                            url = window.App.APIUrl + album.photos[0].file.url
                        }
                        return (
                            <Col key={index} xs={{span : 32}} sm={{span : 24}} md={{span : 16}} lg={{span : 8}}>
                                <Tooltip placement="top" title={album.photos.length + " photos dans l'album"}>
                                <Card
                                    onClick={()=>this._onAlbumSelect(album)}
                                    className={"AlbumCard"}
                                    hoverable={hoverActive}
                                    style={{width: 240, boxShadow : '0px 0px 5px 0px ' + album.couleur}}
                                    cover={
                                        <figure>
                                            <img  alt={album.nom}
                                                  src={url}/>
                                        </figure>}
                                >
                                    <Meta  title={album.nom} description={album.année}/>
                                </Card>
                                </Tooltip>
                            </Col>
                        )
                    })}

                </Row>
            </div>
        )
    }
}

export default Albums;
