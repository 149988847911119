import React from "react";

import {Badge, Slider, Button, message, Result, Spin, Alert} from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';

/***Style***/
import "./style/Home.page.scss";

import Gallery from "react-photo-gallery";

/***Component***/

import PhotoModal from "../component/PhotoModal.component";

class Home extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            displayPicture : null,
            openModal : false,
            pictureModal : null,
            pictureModalIndex : null,
            minMax : [],
            marks : {},
            album : false,
            promoDate : [],
            onLoad : false,
            timeType : true
        };

        this.App = window.App;
        this.API = window.App.API;
        window.App.Home = this;

        this._updateMark = this._updateMark.bind(this)
    }

    componentDidMount() {
        const album = JSON.parse(sessionStorage.getItem("album"));

        if (album){
            this._searchPictures("",false, album);
            this.setState({album : true});
        }
        else {
            this._searchPictures("");
        }
        this._getTimeMarks();
    }

    /**
     * Appel d'API permettant de filtrer les images à l'affichage
     * @param search
     * @param selectDate
     * @param Album
     * @returns {Promise<void>}
     * @private
     */
    async _searchPictures(search, selectDate, Album){
        var {minMax, onLoad} = this.state;

       this.setState({onLoad : true});

        if (!onLoad){
        if (selectDate){
            await this.getPromobyDate(selectDate)
            minMax = [selectDate, selectDate]
        }
        else {
            minMax = [1937, new Date().getFullYear()]
        }

        var displayPicture = [];
        let formatMin = moment(new Date(minMax[0],0,1)).format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
        let formatMax = moment(new Date(minMax[1],11,31)).format(moment.HTML5_FMT.DATETIME_LOCAL_MS);

        try{
            if (Album){
                var APIPictures = await this.API.getEntries("photos?_limit=-1&promotions="+Album)
            }
            if (search) {
                var APIPictures = await this.API.getEntries("photos?_limit=-1&description_contains="
                    +search+"&_sort=pdv:DESC");
            }
            else{
                var APIPictures = await this.API.getEntries("photos?_limit=-1&&pdv_gte="+formatMin+"&publish=1&pdv_lte="+formatMax+"&_sort=pdv:DESC");
            }

            APIPictures.map((picture, index) => {
                displayPicture.push(
                    {
                        src: this.App.APIUrl + picture.file.url,
                        width: 16,
                        height: 9,
                        comments : picture.comments,
                        raw : picture
                    }
                )
            });
            message.destroy();
            if (APIPictures.length === 0){
                displayPicture = null;
                message.warning('Aucune photo pour votre recherche. Essayez de modifier le selecteur de dates ou de cliquez sur une promotion');
            }
            else {
                message.success('Recherche terminée');
            }
            setTimeout(()=>{this.setState({displayPicture : displayPicture, onLoad : false});},500);

        }catch (e) {
            this.setState({displayPicture : null});
            message.error('API Indisponible');
        }
        }
    }

    /**
     * Appel d'API permettant récupérer les timeMarks
     * @private
     */
    async _getTimeMarks(){
        Number.prototype.mod = function(n) {
            var m = (( this % n) + n) % n;
            return m < 0 ? m + Math.abs(n) : m;
        };

        var marks = {};
        try{
            var APIMarks = await this.API.getEntries("promotions?_limit=-1");

            APIMarks.map((mark, index)=>{

                if ( mark.photos.length > 0 &&  index.mod(10) === 1 ){
                marks[mark.année] = {
                    style: {
                        color: mark.couleur,
                    },
                    nom : mark.nom,
                    date : mark.année,
                    label: <span>{mark.nom}</span>,
                }
                }
            });

            marks[1938] = {
                label: <strong>P1938</strong>,
            };
            marks[2007] = {
                label: <span>P140</span>,
            };
            marks[new Date().getFullYear()] = {
                label: <strong>{new Date().getFullYear()}</strong>,
            };
            this.setState({marks : marks})
        }catch (e) {
            console.log(e);
            message.error('API Indisponible');
        }
    }

    async getPromobyDate(date) {
        try {
            var APIPromo = await this.API.getEntries("promotions?_limit=-1&année=" + date);
            this.setState({promoDate : APIPromo})
        }
        catch (e) {
            console.log(e);
            message.error('API Indisponible');
        }
    }

    _updateMark(promotion) {
        var {marks} = this.state

        if (typeof marks[promotion.année] !== "undefined"){
            marks[promotion.année] = {
                style: {
                    color: promotion.couleur,
                },
                nom : promotion.nom,
                date : promotion.année,
                label: <strong>{promotion.nom}</strong>,
            }
        }

        this.setState({marks : marks})

    }

    _imageRenderer
        ({ index, left, top, key, photo }){
        var pcolor = 'white';
        var {displayPicture} = window.App.Home.state;
        if (typeof photo.raw.promotions[0] !== "undefined"){
            pcolor = photo.raw.promotions[0].couleur
        }
            return (
                <div key={key} style={{
                    display : "block",
                    position : "absolute",
                    margin : "2px",
                    left : left,
                    top : top,
                    boxShadow : '0px 0px 5px 0px ' + pcolor
                }}
                     onClick={()=> window.App.Home.setState({openModal : true, pictureModal : displayPicture[index], pictureModalIndex : index})}
                >
                    <Badge count={photo.comments.length} style={{ backgroundColor: pcolor }}>
                        <div className={"lazyPhoto"}
                        //onMouseEnter={() => window.App.Home._updateMark(photo.raw.promotions[0])}
                        >
                            <Button className={'openModal'} shape="circle" icon="search" />
                            <LazyLoadImage
                                effect="opacity"
                                alt={photo.alt}
                                height={photo.height}
                                src={photo.src}
                                width={photo.width} />
                        </div>
                    </Badge>
                </div>
            )
    }


    render (){
        var {displayPicture, openModal, pictureModal, minMax, marks, pictureModalIndex, album, onLoad} = this.state;

        return(
            <Spin tip="Chargement..." spinning={onLoad}>
            <div id="Home">
                {album &&
                <div>
                    <Alert message={this._renderAlert()} type="info" />
                    <Button onClick={()=>{
                        sessionStorage.setItem("album", null);
                        this.setState({album: false});
                        this._searchPictures("", false)}} style={{marginBottom : 10, marginTop: 10}} type="secondary" icon="delete">
                        Supprimer le filtre
                    </Button>
                </div>
                }

                <div className={'timeBar'}>
                    {/**<Switch className={"timeSwitch"}
                            onChange={()=>{
                                if(timeType){
                                    this.setState({timeType : false})
                                } else {
                                    this.setState({timeType : true})
                                }
                            }}
                            checkedChildren="Années"
                            unCheckedChildren="Promos" />**/}
                    <Slider
                        onAfterChange={(value)=>{
                        this.setState({minMax : value, album : true});
                        message.destroy();
                        message
                            .loading('Chargement en cours', 0);
                        this._searchPictures("", value);
                    }}
                            vertical
                            included={false}
                            marks={marks}
                            defaultValue={2020}
                            min={1938}
                            max={new Date().getFullYear()} />

                </div>
                {displayPicture
                    ? <Gallery renderImage={this._imageRenderer}
                               photos={displayPicture}
                               direction={"column"} />
                    : <Result
                        title="Il n'y a rien à afficher"
                    />
                }
                {openModal &&
                <PhotoModal visible={openModal} picture={pictureModal} index={pictureModalIndex}/>
                }
            </div>
            </Spin>
        )
    }

    _renderAlert() {
        const {minMax, promoDate} = this.state;

        return(
            <span>Vous affichez actuellement les photos de {minMax} ( {promoDate.map((promo)=>{ return promo.nom+" "})})</span>
        )
    }

}

export default Home;
