import React from 'react';
import { Redirect, Route } from 'react-router-dom';

var me = JSON.parse(sessionStorage.getItem('me'));

if (!me) {
    me = {role : {id : 0}}
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            me.role.id === 3 ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

export default PrivateRoute;
