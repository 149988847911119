import React from "react";

import {Avatar, Modal, Comment, List, Skeleton, Form, Input, Button, message, Tag, Row, Col, Alert, Popconfirm, Icon} from 'antd';
import './style/PhotoModal.component.scss';

const { TextArea } = Input;



class PhotoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            comments: [],
            newComment : "",
            count : 3,
        };

        this.App = window.App;
        this.API = window.App.API;
    }

    async componentDidMount() {
        const {picture} = this.props;

        try {
            const responses = await this.API.getEntries('comments?photo.id='
                + picture.raw.id);
            this.setState({comments: responses, loading: false});
        } catch (err) {
            message.error('API Indisponible');
        }

    }

    async setComment(){
        var {newComment} = this.state;
        const {picture} = this.props;

        this.setState({loading : true});

        try{
           await this.API.createEntry('comments', {
                description :  newComment,
                user : this.App.state.me.id,
                photo : picture.raw.id
            });
            message.success('Message publié');
            this.setState({loading : false});
            this.componentDidMount();
        }catch (e) {
            message.error('API Indisponible');
        }
    }

    async _deletePicture(){
        var {newComment, count} = this.state;
        const {picture} = this.props;

        try{
            await this.API.deleteEntry('photos', picture.raw.id);
            setInterval(()=> {
                this.setState({count : count -=1});
                    message.destroy()
                    message.loading('Photo supprimmer de la plateforme. Redirection dans '+ count+'s');

                }
            , 1000);

            setTimeout(()=>window.location.pathname = '/', 3000)
        }catch (e) {
            message.error('API Indisponible');
        }
    }

    async _deleteComment(id){
        try{
            await this.API.deleteEntry('comments', id);
            message.success('Message supprimer');
            this.setState({loading : false});
            this.componentDidMount();
        }catch (e) {
            message.error('API Indisponible');
        }
    }

    render() {
        const {loading, comments} = this.state;
        const {visible, picture, index} = this.props;

        var commentsIs = false;
        if (picture.comments.length > 0){commentsIs = true}

        var pdv = new Date(picture.raw.pdv);
        var fullPdv = pdv.getDate() + "/" + (pdv.getMonth() + 1) +"/"+ pdv.getFullYear();

        return (
            <div id="PhotoModal">
                <Modal
                    className={'theModal'}
                    visible={visible}
                    title={picture.raw.titre + " le " + fullPdv}
                    onCancel={() => window.App.Home.setState({openModal: false})}
                    footer={""}
                >
                    <Row style={{paddingBottom : 24}}>
                        <Col span={24}>
                            {picture.raw.promotions.map((promotion, index)=>
                                (<Tag key={index} color={promotion.couleur}>{promotion.nom}</Tag>)
                            )}
                            {this.App.state.me && this.App.state.me.role.id === 3 &&
                            <Popconfirm onConfirm={()=>this._deletePicture()} title="Etes vous sur de vouloir supprimer cette photo ？" okText="Oui" cancelText="Non">
                                <Button shape="circle" icon="delete" />
                            </Popconfirm>}
                        </Col>
                    </Row>
                    <img style={{width: '100%'}} alt={picture.title} src={picture.src}/>
                    {index > 0 &&
                        <Icon onClick={()=>window.App.Home.setState({pictureModal : window.App.Home.state.displayPicture[index - 1],
                            pictureModalIndex : index - 1 })} className={"ModalIcon SwiftLeft"} type="left" />
                    }
                    {index < window.App.Home.state.displayPicture.length &&
                        <Icon  onClick={()=>window.App.Home.setState({pictureModal : window.App.Home.state.displayPicture[index + 1],
                            pictureModalIndex : index + 1 })} className={"ModalIcon SwiftRight"} type="right" />
                    }
                    <Alert
                        message="Description"
                        description={picture.raw.description}
                        type="info"
                        showIcon
                    />
                    {commentsIs &&
                    <List
                        className="comment-list"
                        header={`${picture.comments.length} commentaires`}
                        itemLayout="horizontal"
                        dataSource={comments}
                        renderItem={item => {
                            return (
                                <Skeleton loading={loading} active avatar>
                                    <li>
                                        <Comment
                                            actions={item.actions}
                                            author={item.user.username}
                                            avatar={
                                                <Avatar size="large">
                                                    {item.user.username}
                                                </Avatar>}
                                            content={
                                                this.App.state.me && this.App.state.me.id === item.user.id ?
                                                    <div style={{display : 'flex', justifyContent : 'space-between'}}>
                                                        <p>{item.description}</p>
                                                        <Popconfirm onConfirm={()=>this._deleteComment(item.id)} title="Etes vous sur de vouloir supprimer ce commentaire ？" okText="Oui" cancelText="Non">
                                                            <Button shape="circle" icon="delete" />
                                                        </Popconfirm>
                                                    </div>
                                                    :
                                                    <p>{item.description}</p>
                                               }
                                            datetime={item.created_at}
                                        />
                                    </li>
                                </Skeleton>
                            )
                        }}
                    />
                    }
                    {this.App.state.me &&
                        <div>
                            <Form.Item>
                                <TextArea rows={4} onChange={event => this.setState({newComment : event.target.value})} />
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={()=>this.setComment()} loading={false}  type="primary">
                                    Ajouter un commentaire
                                </Button>
                            </Form.Item>
                        </div>
                    }
                </Modal>
            </div>
        )
    }

}

export default PhotoModal;
