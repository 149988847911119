import React from "react";

import {Form, Input, Tooltip, Icon, Cascader, Checkbox, Button, notification, message,} from 'antd';

import ReCAPTCHA from "react-google-recaptcha";

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promoList: null,
            username: "",
            password: null,
            password2: null,
            email: null,
            promo: null,
            cgu: false,
            cap : true,
        };
        this.API = window.App.API;
    }

    async _getPromotion() {
        var promoList = [];
        try {
            var APIPromo = await this.API.getEntries("promotions?_sort=année:DESC&_limit=200");

            APIPromo.map((promo, index) => {
                promoList.push(
                    {
                        value: promo.nom,
                        label: promo.nom,
                    }
                )
            });
            this.setState({promoList: promoList})
        } catch (e) {
            message.error('API Indisponible');
        }
    }

    componentDidMount() {
        this._getPromotion();
    }

    /***Register***/
    //Permet l'enregistrement d'un nouvel utilisateur'
    Register(username, email, password) {
        try {
            this.API.register(username, email, password).then(
                () => {
                    this.Login(username, password);
                    this.setState({
                        formular: false,
                        connect: false,
                        register: false,
                    })
                }
            ).catch((e) => {
                    message.error(e.toString())
                }
            );
        } catch (e) {
            message.error('API Indisponible');
        }
    }

    /***Login***/
    //Permet la connexion d'un utilisateur
    Login(username, password) {
        try {
            this.API.login(username, password).then(
                (data) => {
                    this.API.getEntry('users', 'me').then((data) => {
                            sessionStorage.setItem("me", JSON.stringify(data));

                            window.App.setState({me: data});
                            window.App.forceUpdate();

                            message.success("Bienvenue " + data.username);
                            window.App.Header.setState({
                                formular: false,
                                connect: false,
                                register: false,
                            });
                        }
                    )
                }).catch((e) => {
                message.error(e.toString())
            });
        } catch (e) {
            message.error('API Indisponible');
        }
    }

    _confirmForm() {
        var {username, promo, email, password, password2, cgu} = this.state;

        try {
            if (password === password2 && password.length >= 7 && email && promo && username.length >= 3 && cgu) {
                notification.destroy();
                notification.success({
                    placement: 'bottomLeft',
                    message: 'Information',
                    description: 'Vous êtes prêt pour l\'aventure ! '
                });
                return false
            } else {
                var description = [];
                if (!ValidateEmail(email)) {
                    description.push(<li key={'email'}>Veuillez renseigner un email valide</li>)
                }
                try {
                    if (password.length <= 7) {
                        description.push(<li key={'pass'}>Votre mot de passe est trop cours (minimum 8 caractères)</li>)
                    }
                    if (username.length <= 3) {
                        description.push(<li key={'username'}>Votre pseudo doit être de minimum 3 caractères</li>)
                    }
                } catch (e) {
                }
                if (password !== password2) {
                    description.push(<li key={'diffpass'}>Vos mots de passes ne correspondent pas</li>)
                }
                if (!promo) {
                    description.push(<li key={'promo'}>Veuillez renseigner votre promotion</li>)
                }
                if (!cgu) {
                    description.push(<li key={'cgu'}>Veuillez accepter les <a href={'/cgu'}>CGU</a></li>)
                }
                notification.destroy();
                notification.info({
                    placement: 'bottomLeft',
                    message: 'Information',
                    description:
                        <ul>{description}</ul>,
                });
            }
            return true;
        } catch (e) {
        }

        function ValidateEmail(mail) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
                return true;
            }
            return false;
        }
    }

    render() {

        var {promoList, username, promo, email, password, cap} = this.state;

        var siteKey = '6Lf96L0UAAAAADx_TD2beQmn4y1EK-Bvs2zCXVrM';

        if(window.location.href === "https://igalerie.arpete.com/" || window.location.href === "http://igalerie.arpete.com/"){
            siteKey = '6Lf8jrAUAAAAAAMBEOD-vB2XM3al-CYu-wZjIo04';
        }

        return (
            <div id="RegisterForm">
                <Form>
                    <Form.Item label="E-mail">
                        <Input onChange={event => {
                            this.setState({email: event.target.value})
                        }}/>
                    </Form.Item>
                    <Form.Item label="Password" hasFeedback>
                        <Input.Password onChange={event => {
                            this.setState({password: event.target.value})
                        }}/>
                    </Form.Item>
                    <Form.Item label="Confirm Password" hasFeedback>
                        <Input.Password onChange={event => {
                            this.setState({password2: event.target.value})
                        }
                        }/>
                    </Form.Item>
                    <Form.Item label="Votre Promotion">
                        <Cascader onChange={event => {
                            this.setState({promo: event[0]})
                        }} options={promoList}/>
                    </Form.Item>
                    <Form.Item
                        label={
                            <span>
                                Pseudo&nbsp;
                            </span>
                        }
                    >
                        <Input disabled={!promo} onChange={event => {
                            this.setState({username: promo + event.target.value})
                        }}/>
                    </Form.Item>
                    <Form.Item
                        label={
                            <span>
                                Username&nbsp;
                                <Tooltip title="Vous sert à vous connecter">
                                    <Icon type="question-circle-o"/>
                                </Tooltip>
                            </span>
                        }
                    >
                        <Input disabled value={username}/>
                    </Form.Item>
                    <Form.Item>
                        <Checkbox onChange={(event) => {
                            this.setState({cgu: event.target.checked});
                        }}>
                            J'accepte les <a href="/cgu">CGU</a> du site
                        </Checkbox>,
                    </Form.Item>
                    <Form.Item>
                        <ReCAPTCHA
                            sitekey={siteKey}
                            onChange={()=> this.setState({cap : false})}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={this._confirmForm()}  onClick={()=>this.Register(username, email, password)} type="primary" htmlType="submit">
                            S'enregistrer
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }

}

export default RegisterForm;
