import React from "react";

import {Form, Upload, Button, Icon, Input, message, Select, DatePicker, Popconfirm} from 'antd';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;

class AddPhoto extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            description : null,
            promoList: [],
            promo : [],
            fileList : [],
            formValid : true,
            pdv : null,
            loading : false,
        };

        this.API = window.App.API;
        window.App.AddPhoto = this;
    }

     _uploadFiles(){
        var {fileList, description, pdv, promo} = this.state;

        this.setState({loading : true});
        function loading(index){
            var percent = (index/fileList.length)*100;
            message.destroy();
            message.loading(Math.round(percent) + '% - Chargement en cours');
        }

        fileList.map((file, index)=>{
            const formData = new FormData();
            formData.append('files', file.originFileObj, file.name);
            window.App.API.upload(formData).then((value => {
                loading(index);
                var data = {
                    titre : value[0].name,
                    pdv : pdv,
                    description : description,
                    file : value[0].id,
                    user : window.App.state.me.id,
                    promotions : promo,
                    created_at : new Date().getTime(),
                };
                window.App.API.createEntry('photos', data);
                if (fileList.length === (index + 1)){
                    message.destroy();
                    message.success('Félicitations ! Vos photos seront validées par un administrateur prochainement');

                    window.App.Header.setState({openDrawer: false, profile : false, addPhoto : false});
                }
            }));
        });

    }

    _tempuploadFiles({onSuccess, onError, file}){

        new Promise((resolve, reject) => {
            resolve('ok');
            message.destroy();
            message.loading("Prise en compte de votre photo", 1)
        }).then((value =>
               onSuccess(null, file)
           ));
    }


    async _getPromotion() {
        var promoList = [];
        try {
            var APIPromo = await this.API.getEntries("promotions?_sort=année:DESC&_limit=200");

            APIPromo.map((promo, index) => {

                promoList.push(
                    <Option key={promo.id}>{promo.nom}</Option>
                )
            });
            this.setState({promoList: promoList})
        } catch (e) {
            message.error('API Indisponible');
        }
    }

    componentDidMount() {
        this._getPromotion();
    }

    _confirmForm() {
        var {description, promo, fileList, pdv} = this.state;

        if (description && promo.length > 0 && fileList.length > 0 && pdv){
            return false
        }
        return true
    }

    render (){

        var {promoList, loading} = this.state;

        const props2 = {
            accept : 'image/* ',
            customRequest : this._tempuploadFiles,
            multiple : true,
            listType: 'picture',
            className: 'upload-list-inline',
            locale : true,
        };

        return(
            <div id="AddPhoto">
                <Form>
                    <Form.Item>
                        <Upload onChange={(value)=>this.setState({fileList : value.fileList})} {...props2}>
                            <Button size={'large'}>
                                <Icon type="picture" /> Photos
                            </Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        label={
                            <span>
                                Description&nbsp;
                            </span>
                        }
                    >
                        <TextArea autosize={true} placeholder={"Cette description sera appliquée à toutes vos photos, elle doit être la plus complète possible (lieux, événement etc.) " +
                        "puisque elle servira à la recherche rapide"}
                                  onChange={(event)=>this.setState({description : event.target.value})}
                                  rows={9} />
                    </Form.Item>
                    <Form.Item label={
                            <span>
                                Promotions&nbsp;
                            </span>
                            }>
                        <Select
                            style={{ width: '100%' }}
                            mode="multiple"
                            onChange={event => {
                                this.setState({promo: event})
                            }}>
                            {promoList}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <DatePicker allowClear={false} onChange={(value)=>this.setState({pdv : value.format(moment.HTML5_FMT.DATETIME_LOCAL_MS)})} defaultValue={moment(new Date(), 'DD/MM/YYYY')} format={'DD/MM/YYYY'} />
                    </Form.Item>
                    <Popconfirm
                        placement="leftTop"
                        onConfirm={()=>this._uploadFiles()}
                        title={'En cliquant sur "Oui" vous acceptez les conditions générales d\'utilisation du site'}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button loading={loading}  disabled={this._confirmForm()} type="primary" shape="round" icon="upload" size={'large'}>
                            Upload
                        </Button>
                    </Popconfirm>
                </Form>
            </div>
        )
    }

}

export default AddPhoto;
