import React from "react";

import {Avatar} from 'antd';

class Profil extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render (){
        var me= window.App.state.me;

        return(
            <div id="Profil">
                <Avatar size="large">
                    {me.username}
                </Avatar>
                <br/>
                <span>
                    Username : {me.username}
                </span>
                <br/>
                <span>
                    Email : {me.email}
                </span>
            </div>
        )
    }

}

export default Profil;