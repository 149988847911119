import React from "react";
import './style/Header.component.scss';

/***ANTD***/

import {Menu, Icon, Input, Drawer} from 'antd';
import LoginForm from "../secure/LoginForm.secure";
import RegisterForm from "../secure/RegisterForm.secure";
import Profil from "../secure/Profil.secure";
import AddPhoto from "../secure/AddPhoto.secure";

const {Search} = Input;

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formular: false,
            connect: false,
            register: false,
            openDrawer : false,
            profile : false,
            addPhoto : false,
        };

        this.App = window.App;
        this.API = window.App.API;
        window.App.Header = this;
    }


    /***Logout***/
    //Permet la deconnexion d'un utilisateur
    Logout() {
        try {
            this.API.clearToken();
            sessionStorage.clear();
            this.App.setState({me : null});

            window.location.pathname ='/';
        } catch (e) {

        }
    }

    render() {

        var {formular, connect, register, openDrawer, profile, addPhoto} = this.state;
        var {me} = this.props;

        return (
            <div id="Header">
                <Menu mode="horizontal">
                    <Menu.Item disabled id={"logo"} style={{float: 'left'}} key="logo">
                        <img className={'AetaLogo'} alt={"AETA"} src={"/assets/Insigne-AETA.png"}/>
                        <h1 className={'AetaTitle'} >Galerie Photos AETA</h1>
                    </Menu.Item>
                    <Menu.Item style={{float: 'right'}} location={"right"} key="search">
                        <Search
                            placeholder="Rechercher une image"
                            onPressEnter={(event => this.App.Home._searchPictures(event.target.value))}
                            onSearch={value => this.App.Home._searchPictures(value)}
                            style={{width: 200}}
                        />
                    </Menu.Item>
                    {!me &&
                    <Menu.Item onClick={() => this.setState({formular: true, connect: true})} style={{float: 'right'}}
                               key="connect">
                        <Icon type="login"/>
                        Connexion
                    </Menu.Item>
                    }
                    {!me &&
                    <Menu.Item onClick={() => this.setState({formular: true, register: true})} style={{float: 'right'}}
                               key="register">
                        <Icon type="plus-circle"/>
                        Enregistrement
                    </Menu.Item>
                    }
                    {me &&
                    <Menu.Item onClick={() => this.Logout()} style={{float: 'right'}} key="logout">
                        <Icon type="logout"/>
                        Deconnexion
                    </Menu.Item>
                    }
                    {me &&
                    <Menu.Item onClick={()=> this.setState({openDrawer : true, profile : true})} style={{float: 'right'}} key="profile">
                        <Icon type="user"/>
                        Profil
                    </Menu.Item>
                    }
                    {me &&
                    <Menu.Item onClick={()=> this.setState({openDrawer : true, addPhoto : true})} style={{float: 'right'}} key="add">
                        <Icon type="plus-circle"/>
                        Ajouter Photos
                    </Menu.Item>
                    }
                    {me && me.role.id === 3 && window.location.pathname !== '/Dashboard' &&
                    <Menu.Item onClick={() => window.location.pathname = '/Dashboard'} style={{float: 'right'}} key="dashboard">
                        <Icon type="sliders"/>
                        Dashboard
                    </Menu.Item>
                    }
                    {window.location.pathname !== '/' &&
                    <Menu.Item onClick={() => window.location.pathname = '/'} style={{float: 'right'}} key="pictures">
                        <Icon type="picture"/>
                        Galerie
                    </Menu.Item>
                    }
                    {window.location.pathname !== '/Albums' &&
                    <Menu.Item onClick={()=> window.location.pathname = '/Albums'} style={{float: 'right'}} key="album">
                        <Icon type="picture"/>
                        Albums
                    </Menu.Item>
                    }
                </Menu>
                <Drawer
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({formular: false, register: false, connect: false})}
                    visible={formular}
                    title={"Bonjour !"}
                >
                    {connect &&
                    <LoginForm/>
                    }
                    {register &&
                    <RegisterForm/>
                    }
                </Drawer>
                <Drawer
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({openDrawer: false, profile : false, addPhoto : false})}
                    visible={openDrawer}
                >
                    {profile &&
                    <Profil/>
                    }
                    {addPhoto &&
                    <AddPhoto/>
                    }

                </Drawer>
            </div>
        )
    }

}

export default Header;
