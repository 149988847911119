import React from "react";

import {Form, Icon, Input, Button, message} from 'antd';

import ReCAPTCHA from "react-google-recaptcha";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null,
            cap : true,
        };

        this.API = window.App.API;
        this.Header = window.App.Header;

    }

    /***Login***/
    //Permet la connexion d'un utilisateur
    Login(username, password) {
        try {
            this.API.login(username, password).then(
                (data) => {
                    this.API.getEntry('users', 'me').then((data) => {
                            sessionStorage.setItem("me", JSON.stringify(data));

                            window.App.setState({me: data});
                            window.App.forceUpdate();

                            message.success("Bienvenue " + data.username);
                            this.Header.setState({
                                formular: false,
                                connect: false,
                                register: false,
                            });
                        }
                    )
                }).catch((e) => {
                message.error(e.toString())
            });
        } catch (e) {
            message.error('API Indisponible');
        }
    }

    render() {

        var {func} = this.props;
        var {username, password, cap} = this.state;
        var siteKey = '6Lf96L0UAAAAADx_TD2beQmn4y1EK-Bvs2zCXVrM';

            if(window.location.href === "https://igalerie.arpete.com/" || window.location.href === "http://igalerie.arpete.com/"){
                siteKey = '6Lf8jrAUAAAAAAMBEOD-vB2XM3al-CYu-wZjIo04';
            }

            if(window.location.href === "http://localhost:3000/"){
                cap = false;
            }

        return (
            <div id="LoginForm">
                <Form layout="inline">
                    <Form.Item>
                        <Input
                            prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder="Username"
                            onChange={event => this.setState({username: event.target.value})}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                            type="password"
                            placeholder="Password"
                            onChange={event => this.setState({password: event.target.value})}
                        />

                    </Form.Item>
                    <Form.Item>
                        <ReCAPTCHA
                            sitekey={siteKey}
                            onChange={()=> this.setState({cap : false})}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={cap} onClick={() => this.Login(username, password)} type="primary" htmlType="submit"
                                size={"large"}>
                            Connexion
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }

}

export default LoginForm;
