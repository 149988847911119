import React from 'react';

import { Result, Button } from 'antd';

class NotFoundPage extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
        };

    }


    render(){

        return (
            <Result
                style={{minHeight : '100vh'}}
                status="404"
                title="404"
                subTitle="Désolé, cette page n'existe tout simplement pas."
                extra={<Button onClick={() => window.location.pathname = '/'} type="primary">Retour à la page principale</Button>}
            />
        )
    }
}

export default NotFoundPage;
