import React from "react";

import {Row, Col, Skeleton} from 'antd';

import './style/Dashboard.page.scss';

class CGU extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            platform : null
        };
        this.App = window.App;
        this.API = window.App.API;
    }

    async componentDidMount() {

        var APIPlatform = await this.API.getEntries("platforms");

        this.setState({
            platform: APIPlatform,
        });
    }


    render() {

        var { platform } = this.state;

        var cgu = null;
        try{
            cgu = platform[0].cgu;
        }
        catch (e) {
        }

        return (
            <div id="CGU">
                <Row>
                    <Col span={15} offset={5}>
                        {!cgu &&
                        <Skeleton active paragraph={{ rows: 50 }} />
                        }
                        <p><pre align={"justify"} style={{whiteSpace : "pre-wrap", paddingBottom : 50}}>
                            {cgu}
                        </pre></p>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CGU;